.login-box {
    background: rgba(252, 252, 252, 0.35) !important;
    border-radius: 15px;
    filter: drop-shadow(0 2px 4px rgb(0, 0, 0, 25%));
    padding: 25px;
}

body {
    background-image: url('https://i.ibb.co/2djF27m/light-blue-wallpaper.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.bg-login {
    background: linear-gradient(180deg, rgba(153, 153, 153, 0.5) 0%, rgba(79, 79, 79, 0.5) 100%),
    url('../../components/city-blur.webp');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .blur {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .blur {
        filter: blur(10px);
        filter: blur(10px);
    }
}