.navbar{
    background: #383838;
    color: #e7e7e7;
}

.icon-nav {
    color: #ffffff;
    font-size: ;
}

.icon-nav:focus {
    outline: 0;
}

.nav-item > a{
    color: #e7e7e7;
    font-size: 1rem;
}

.dropdown-menu{
    background-color: #383838;
    border: none;
}

.dropdown-menu > a {
    color: #e7e7e7;
}