.leaflet-container {
    height: 500px;
    width: 100%;
    margin-top: 10px;
    border-radius: 15px;
    z-index: 0;
}

.location-map{
    height: 350px;
}

@media (min-width: 1200px) {
    .location-map {
        height: 579px;
    }
}