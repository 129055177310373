.facility-card{
    min-height: 600px;
}

.pax-container {
    background: #4791ce;
    display: inline-block;
    padding: 7px 12px;
    border-radius: 10px;
    color: white;
}

@media (min-width: 576px) {
    .facility-card{
        min-height: 624px;
    }
}

body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Poppins', sans-serif !important;

}

@media (min-width: 992px) {
    .facility-card{
        min-height: 603px;
    }
}