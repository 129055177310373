.quota-card{
    width: auto;
    height: auto;
    padding: 1.2rem;
    /* background: rgba(71, 145, 206, 0.2); */
    border-radius: 15px;
}

.border-radius-15 {
    border-radius: 15px;
}

.img-quota{
    position: absolute;
    left: 0;
    top: 0;
    opacity: .5;
}

@media (min-width: 1024px) {
    .col-md-6-quota {
        max-width: 23%;
        flex: 0 0 23%;
    }

    .quota-crd:nth-child(1),
    .quota-crd:nth-child(2),
    .quota-crd:nth-child(3) {
        margin-right: 2.6%;
    }
}

@media (min-width: 866px) {
    .col-md-6-quota {
        max-width: 23%;
        flex: 0 0 23%;
    }

    .quota-crd:nth-child(1),
    .quota-crd:nth-child(2),
    .quota-crd:nth-child(3) {
        margin-right: 2.6%;
    }
}

@media (min-width: 426px) and (max-width: 991px) {
    .quota-crd:nth-child(1),
    .quota-crd:nth-child(2),
    .quota-crd:nth-child(3) {
        margin-right: 1.5%;
    }

    .quota-crd:nth-child(2),
    .quota-crd:nth-child(3),
    .quota-crd:nth-child(4) {
        margin-left: 1.5%;
    }

    .col-md-12-quota {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .col-md-6-quota {
        max-width: 22.7%;
        flex: 0 0 22.7%;
    }
}

@media (min-width: 320px) and (max-width: 425px) {
    .quota-crd:nth-child(3),
    .quota-crd:nth-child(4) {
        margin-top: 1.25rem;
    }
    
    .quota-crd:nth-child(1),
    .quota-crd:nth-child(3) {
        margin-right: 3%;
    }

    .quota-crd:nth-child(2),
    .quota-crd:nth-child(4) {
        margin-left: 3%;
    }

    /* .quota-crd:nth-child(3),
    .quota-crd:nth-child(5) {
        margin-right: 0 !important;
    } */

    .col-md-12-quota {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .col-md-6-quota {
        max-width: 47%;
        flex: 0 0 47%;
    }
}

