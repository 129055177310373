.bg-white {
    background-color: white;
    margin: 0;
    padding: 0;
}

.lh-16 {
    line-height: 16px;
}

.bg-timeslot {
    background: #E91E63 !important;
    color: white;
}

.tab-container {
    padding: 2.5rem 1.5rem 1.5rem;
    background: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}

.nav-tabs .nav-link {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tab-line .nav-item .nav-link {
    margin-right: 9px;
    background: whitesmoke;
}

.big-tabs{
    width: 203px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background: white;
}

.bg-voffice {
    background-color: #9a2b59;
    color: white;
}

.tab-line .nav-item .nav-link.active {
    color: #9a3659;
    border-bottom: 2px solid #9a3659;
}

.title-font {
    font-weight: 700;
}

.col-timeslot {
    max-width: 16.66667%;
    flex: 0 0 16.66667%;
    padding: 5px;
}

.guide-lines > ul {
    list-style-type: none;
    position: relative;
}

.datepicker-inpt {
    font-size: .9rem;
    font-weight: 500;
    line-height: 1.5;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    color: #3e4676;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #d3e0e9;
    background-color: #fff;
    background-clip: padding-box;
}

.datepicker-inpt:focus{
    outline: none;
}

.guide-lines>ul>li::before {
    content:'';
    display:inline-block;
    position: absolute;
    left: 11px;
    height:1em;
    width:1em;
    background-image:url('../../../components/check-circle.png');
    background-size:contain;
    background-repeat:no-repeat;
    padding-left: 2em;
}

.mobile-ui {
    display: none;
}

.bg-available:hover {
    cursor: pointer;
    background: rgb(111, 34, 236) !important;
    border-color: rgb(111, 34, 236) !important;
    color: #fff;
    transition: background 0.3s ease-in, color 0.3s ease-in, border-color 0.3s ease-in;
}

.bg-booked {
    background: rgb(148, 148, 148) !important;
    color: #fff;
    border-color: rgb(148, 148, 148) !important;
}

.bg-booked:hover{
    cursor: not-allowed;
}

@media (min-width: 1025px) and (max-width: 1598px) {
    .col-timeslot {
        max-width: 33.33333%;
        flex: 0 0 33.33333%;
    }
}

@media (min-width: 320px) and (max-width: 603px) {
    .col-timeslot {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

@media (min-width: 320px) and (max-width: 768px) {

    .react-datepicker-wrapper {
        display: block !important;
    }

    .cstm-mb-4 {
        margin-bottom: 1.5rem !important
    }


    .nav-tabs .nav-link {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .desktop-ui {
        display: none;
    }

    .mobile-ui {
        display: flex;
    }

    .nav-link:last-child {
        margin-right: 0px !important;
    }

    .tab-container {
        padding: 1.5rem 1.2rem;
        border-top-right-radius: 0;
    }
}
.time-slot-card {
    border: 1px solid #0c9;
}
