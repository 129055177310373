body{
    background-image: none;
    background-color: #F5F6F8 !important;
    color: #3A3A3A;
    font-family: 'Poppins', sans-serif;
}

.card-container {
    padding: 1rem;
    background: #ffffff;
}

.hidden {
    opacity: 0;
}

.fab-button {
    -webkit-transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}

.notify-badge {
    position: absolute;
    bottom: 0;
    border-radius: 20px;
    padding: 4px 6px;
    min-width: 19.8px;
}

.form-control,
.custom-select {
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
}

.bg-primary {
    background: #B54674 !important;
}

.bg-primary02 {
    background: rgba(154, 43, 89, 0.2) !important;
    border-radius: 5px;
}

.color-primary {
    color: #B54674 !important;
}

.btn-outline-primary {
    color: #B54674;
    border-color: #B54674;
}

.p-fixed {
    position: fixed;
    width: 100%;
    z-index: 1;
    min-height: auto !important;
    transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}

.btn-outline-primary:hover {
    color: #fff;
    border-color: #B54674;
    background-color: #B54674;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #B54674;
    background-color: #B54674;
}



.icon-size {
    font-size: 20px;
}

.container-icon {
    width: 33.83px;
    padding: 2px 6px;
    /* background: #ff9800; */
    background: linear-gradient(180deg, #c13a952e 0%, rgba(154, 43, 89, 0) 100%);
    margin-left: auto;
    margin-right: auto;
    color: #B54674;
    border-radius: 50%;
}

.bg-primary-linear {
    background: linear-gradient(180deg, #B54674 0%, #AF3190 100%);
}

.bg-orange {
    background: #ff9800;
}

.bg-green {
    background: #4caf50;
}

.section-top-hero{
    background: url('../../components/hero_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.zi-1 {
    z-index: 1;
}

.card {
    border: 2px solid #00000017;
    border-radius: 10px;
}

.quota-card-container {
    margin-top: 66px;
}

.opacity-01 {
    opacity: 0.1;
}

.opacity-06 {
    opacity: 0.6;
}

.font-sb {
    font-weight: 600;
}

.font-rg {
    font-weight: 400;
}

.font-size-vbig {
    font-size: 2.25rem;
    line-height: 2.5rem;
}

.font-size-sm {
    font-size: 0.75rem;
}

.font-size-rg {
    font-size: 1rem;
}

.font-size-md {
    font-size: 1.75rem;
}

.font-size-lg {
    font-size: 2rem;
}

.slick-prev:before,
.slick-next:before {
    font-size: 30px !important;
}

.slick-prev,
.slick-next {
    width: 30px !important;
    height: 30px !important;
    top: 45% !important;
}

.slick-prev {
    left: 0 !important;
    z-index: 1;
}

.slick-next {
    right: 0 !important;
}

.slick-dots {
    position: relative !important;
    bottom: 0px !important;
}

.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: #B54674 !important;
}

.mb-16 {
    margin-bottom: 1rem;
}

.mr-20 {
    margin-right: 1.25rem;
}

.bg-img {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
}

.quota-meeting{
    background: url("../../components/meeting-room-bg.png");
}

.quota-private {
    background: url("../../components/private-office-bg.png");
}

.quota-eventspace {
    background: url("../../components/event-space.png");
}

.quota-workstation {
    background: url("../../components/workstation.png");
}

.img-fluid {
    border-radius: 15px;
}

.quota-active {
    background: linear-gradient(90deg, rgb(136, 48, 84) 30%, rgba(181, 70, 116, 0.5) 100%) !important;
    color: #ffffff;
}

.quota-blue {
    background: linear-gradient(90deg, rgb(48, 112, 163) 30%, rgba(71, 145, 206, 0.5) 100%) !important;
    color: #ffffff;
}

.quota-orange {
    background: linear-gradient(90deg, rgb(185, 116, 12) 30%, rgba(235, 144, 8, 0.5) 100%) !important;
    color: #ffffff;
}

.quota-green {
    background: linear-gradient(90deg, rgb(66, 138, 22) 30%, rgba(82, 173, 27, 0.5) 100%) !important;
    color: #ffffff;
}

.opacity-07 {
    opacity: 0.7;
}

.white {
    color: white;
}

.w-vw100 {
    width: 100vw;
}

.white-06 {
    color: rgba(255, 255, 255, 0.6);
}

.col-md-2 {
    width: 10%;
}

.col-md-10 {
    width: 90%;
}

.col-md-12 {
    width: 100%;
}

.banner-promo {
    width: 100%;
    height: 165px;
    overflow: hidden;
    max-height: 267px;
    border-radius: 15px;
}

.facility-book-show-container {
    margin-top: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-book-show {
    width: 100%;
    height: 450px;
    background: yellow;
    margin: 0%;
    border-radius: 0px;
}

.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
}

.tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
}

.badge-primary {
    color: #fff;
    background-color: #bd4b7ab3;
}

.border-top {
    border-top: #3A3A3A;
    padding-top: 0.75rem !important;
}

.navigate-me {
    text-align: right;
}

@media (min-width: 1024px) {
    .container-cstm {
        max-width: 1024px;
    }

    .card-container-m {
        margin: 1rem;
        border-radius: 15px;
    }

    .card-container-m:nth-child(1) {
        margin-bottom: 0;
    }

    .quota-card-container {
        margin-top: 86px !important;
    }

    .mb-avatar {
        margin-bottom: 0;
    }
    
    .content-big {
        display: block !important;
    }

    .content-sm {
        display: none !important;
    }

    .pr-promo {
        padding-right: 1rem !important;
    }
    
    .top-container {
        margin-top: 86px !important;
    }

    .comming-soon {
        position: absolute;
        bottom: -70px;
        width: 100%;
    }

    .comming-soon>h1 {
        font-size: 63px;
    }
}

@media (min-width: 1024px) and (max-width: 1061px) {
    .banner-promo {
        height: 245px;
    }
}

@media (min-width: 1061px) and (max-width: 1219px) {
    .banner-promo {
        height: 226px;
    }
}

@media (min-width: 800px){
    .connect-crd:nth-child(2),
    .connect-crd:nth-child(3),
    .connect-crd:nth-child(4) {
        margin-right: 2.6%;
    }

    .d-lg-none {
        display: none !important;
    }

    .short-cut-nav {
        margin-left: 1rem;
        margin-right: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 0.5rem;
        background: white;
        border-radius: 15px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1)
    }
    
    .short-cut-nav {
        margin: 1rem 1rem 0;
    }

    .container-sc-nav {
        margin-top:1rem;
    }

    .col-container-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

@media (min-width: 768px){
    .text-md-right {
        text-align: right !important;
    }
}

@media (min-width:320px) and (max-width:767px) {
    .comming-soon {
        position: absolute;
        bottom: -120px;
        width: 100%;
    }

    .comming-soon>h1 {
        font-size: 33px;
    }

    .comming-soon>h4 {
        font-size: 15px;
    }
}

@media (min-width:320px) and (max-width:799px) {
    .connect-crd:nth-child(2),
    .connect-crd:nth-child(3),
    .connect-crd:nth-child(4) {
        margin-right: 2.6%;
    }

    .navigate-me {
        text-align: left;
        margin-top: 10px;
    }
}

@media (min-width:800px) and (max-width: 1023px) {
    .container-sc-nav {
        top: 167px !important;
    }
}

@media (min-width:768px) and (max-width: 1023px) {
    .comming-soon {
        position: absolute;
        bottom: -70px;
        width: 100%;
    }
}

@media (min-width: 320px) and (max-width: 1023px) {

    .short-cut-nav {
        margin-left: 4%;
        margin-right: 4%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 92%;
        padding: 0.5rem;
        background: white;
        border-radius: 15px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1)
    }

    .container-sc-nav {
        position: absolute;
        top: 180px;
        left: 0;
        right: 0;
        width: 100vw;
        z-index: 1;
    }

    .pt-4-5rem {
        padding-top: 4.5rem !important;
    }

    .bg-sm-white {
        background: white;
    }

    .section-top-hero {
        height: 225px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .pr-promo {
        padding-right: 0;
    }

    .avatar-col {
        max-width: 65px;
        flex: 0 0 65px;
    }

    .content-sm {
        display: block !important;
    }

    .content-big {
        display: none !important;
    }

    .quota-card-container:nth-child(2) {
        margin-top: 0px;
    }

    .pb-5-cstm {
        padding-bottom: 2rem !important;
    }

    .pt-5-cstm {
        padding-top: 2rem !important;
    }

    .pt-4-cstm {
        padding-top: 1.5rem !important;
    }

    .p-3-cstm {
        padding: 1rem !important;
    }
    
    .mb-3-cstm {
        margin-bottom: 1rem !important;
    }

    .card-table-container {
        margin-bottom: 1rem;
    }
        

    .vportal-table-container {
        display: block;
        overflow-x: auto;
    }

    .card-container-m {
        margin: 0 0 1rem 0;
    }

    .banner-promo {
        height: 120px;
    }

    .slick-prev,
    .slick-next {
        display: none !important;
    }

    .col-container-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .top-container {
        margin-top: 66px !important;
    }
}

.vportal-table-container {
    border-radius: 10px;
    background-color: #e0e0e07c !important;
}

.vportal-table thead tr th {
    color: #B54674;
}

.vportal-table tbody tr {
    color: #242424;
    font-weight: 400;
}

.vportal-table thead th,
.vportal-table tbody td {
    border-top: none;
    background-color: transparent;
}

.table-container {
    background-color: #fff;
}